var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "block" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "block", height: "auto" },
                            },
                            [
                              _c("file-pond", {
                                ref: "pond",
                                attrs: {
                                  name: "file",
                                  "label-idle":
                                    "<div><span class='icon' style='padding-top:20px; font-size: 3rem;'><i class='fas fa-3x fa-cloud-upload-alt'></i></span></div><div>Click here or drop one or more files here to upload</div>",
                                  "allow-multiple": "true",
                                  "accepted-file-types":
                                    "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                  files: _vm.input.files,
                                  dropOnElement: false,
                                  dropOnPage: true,
                                },
                                on: {
                                  addfile: _vm.beforeFileAdd,
                                  removefile: _vm.removeFile,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.reportErrors.length > 0,
                              expression: "reportErrors.length>0",
                            },
                          ],
                          staticClass: "has-background-warning has-text-white",
                          staticStyle: { padding: "0.5rem 1rem 1rem 1rem" },
                        },
                        [
                          _c("span", { staticClass: "has-text-weight-bold" }, [
                            _vm._v("Error"),
                          ]),
                          _c(
                            "ul",
                            { attrs: { id: "example-1" } },
                            _vm._l(_vm.reportErrors, function (err, idx) {
                              return _c(
                                "li",
                                {
                                  key: err + idx,
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [_vm._v(_vm._s(err))]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "column" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "block" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.input.sendEmail,
                            expression: "input.sendEmail",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "sendEmail",
                          name: "sendEmail",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.input.sendEmail)
                            ? _vm._i(_vm.input.sendEmail, null) > -1
                            : _vm.input.sendEmail,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.input.sendEmail,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.input,
                                    "sendEmail",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.input,
                                    "sendEmail",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.input, "sendEmail", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "is-size-7",
                          staticStyle: { padding: "0px 7px" },
                          attrs: { for: "sendEmail" },
                        },
                        [_vm._v("Send email notification")]
                      ),
                      _vm.input.sendEmail
                        ? _c(
                            "div",
                            {
                              staticClass: "field",
                              staticStyle: {
                                "padding-top": "7px",
                                "padding-left": "20px",
                              },
                            },
                            [
                              _c("label", { staticClass: "label is-size-7" }, [
                                _vm._v("Email Template"),
                              ]),
                              _c("div", { staticClass: "control" }, [
                                _c("div", { staticClass: "select" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.emailTemplateId,
                                          expression: "input.emailTemplateId",
                                        },
                                      ],
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.input,
                                            "emailTemplateId",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { disabled: "", selected: "" },
                                        },
                                        [_vm._v("Select a template to use")]
                                      ),
                                      _vm._l(
                                        _vm.emailTemplates,
                                        function (template) {
                                          return _c(
                                            "option",
                                            {
                                              key: template.id,
                                              domProps: { value: template.id },
                                            },
                                            [_vm._v(_vm._s(template.name))]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("div", { attrs: { id: "buttongroup" } }, [
                  _c("div", { staticClass: "field is-grouped" }, [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button is-accent",
                          attrs: {
                            disabled:
                              _vm.isSaving || _vm.input.files.length < 1,
                          },
                          on: { click: _vm.publish },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.isSaving ? "Publishing" : "Publish") +
                              " "
                          ),
                          _vm.isSaving
                            ? _c("spin-loader", {
                                staticStyle: { "margin-left": ".75rem" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light",
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [_c("span", { staticClass: "has-text-weight-bold" }, [_vm._v("Reports")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Notifications"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }